.homeimagecontainer {
  padding: 20px;
  height: 60%;
  width: auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  .email {
    color: white;
    margin-top: -20%;
    font-size: 2vw;
    a {
      color: white;
      text-decoration: none;
    }
  }
}

.container {
  background-color: black;
  padding-top: 80px;
  padding-bottom: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}
