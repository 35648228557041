/* ========================================================= */
/*                      Layout / Alignment                   */
/* ========================================================= */

.u-verticallyCenterMyChild {
  width:100%;
  height:100%;
  position:relative;
  display:table;
}

.u-verticallyCenterMyChild .u-verticallyCenteredChild {
  display:table-cell;
  vertical-align:middle;
}

.u-alignLeft {
  float:left;
}
.u-alignRight {
  float:right;
}
.u-alignCenter {
  display:block;
  margin-left:auto;
  margin-right:auto;
}
.u-contentsLeft {
  text-align:left;
}
.u-contentsRight {
  text-align:right;
}
.u-contentsCenter {
  text-align:center;
}

.u-alignTop { vertical-align:top; }
.u-alignMiddle,.u-middle { vertical-align:middle; }
.u-alignBottom { vertical-align:bottom; }

.u-clear { clear:both; }

.u-block { display:block; }
.u-inline { display:inline; }
.u-inlineBlock { display:inline-block; }

.u-borderBox, *, *:after, *:before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.u-borderBoxOff { 
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}


.u-overflowHidden { overflow:hidden; }
.u-overflowAuto { overflow:auto; }

.u-absolute { position:absolute; }
.u-relative { position:relative; }
.u-fixed { position:fixed; }

.u-top { top:0; }
.u-bottom { bottom:0; }
.u-left { left:0; }
.u-right { right:0; }

.u-flex { display: flex; }
.u-flexWrap { flex-wrap: wrap; }